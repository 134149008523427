import smoothscroll from 'smoothscroll-polyfill'

export default function smoothScrolling() {
    if (CSS.supports('scroll-behavior', 'smooth')) return
    smoothscroll.polyfill()
    const links = document.querySelectorAll('a[href^="#"]')
    links.forEach(link => {
        const id = link.attributes['href'].value.slice(1)
        const target = document.getElementById(id)
        link.addEventListener('click', () => {
            target.scrollIntoView({ behavior: 'smooth' })
        })
    })
}