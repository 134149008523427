import setFocus from '../_tools/keyboard-trap'

/*
    Menu. Not much more to say.
*/

// Elements
const header = document.querySelector('[data-header]')
const headerLogo = document.querySelector('[data-header-logo]')
const hamburger = document.querySelector('[data-hamburger]')
const menu = document.querySelector('[data-menu]')
const menuLinks = menu.querySelectorAll('[data-menu-link]')
const body = document.querySelector('body')

// Classes
const activeMenuClass = 'menu-is-active'
const hiddenClass = 'is-hidden'

function menuFunction() {

    hamburger.addEventListener('click', function() {
        console.log('clicked hamburger')
        if (header.classList.contains(activeMenuClass)) {
            console.log('menu is open')
            closeMenu()
        } else {
            openMenu()
        }
    })

    menuLinks.forEach(link => {
        link.addEventListener('click', function() {
            if (link.getAttribute('data-menu-link') == 'internal') {
                closeMenu()
            }
        })
    })
}

function openMenu() {
    header.classList.add(activeMenuClass)
    body.classList.add(hiddenClass)
    setFocus(menu)
}

function closeMenu() {
    console.log('closing menu')
    header.classList.remove(activeMenuClass)
    body.classList.remove(hiddenClass)
}

export { menuFunction, openMenu, closeMenu }