export default function menuOverlays() {
    const triggers = document.querySelectorAll('[data-menu-overlay-trigger]')
    const body = document.querySelector('body')
    let type
    let targetMenu
    const activeClass = 'is-active'

    if (triggers.length > 0) {
        triggers.forEach(trigger => {
            trigger.addEventListener('click', function() {
                type = this.getAttribute('data-menu-overlay-trigger')
                targetMenu = document.querySelector(`[data-menu-overlay=${type}]`)
                body.classList.add('is-hidden')
                targetMenu.classList.add(activeClass)
            })
        })
    }
}