export default function menuList() {
    const listWrap = document.querySelector('[data-menu-list]')
    const list = document.querySelector('[data-menu-list] ul')
    const leftArr = document.querySelector('[data-menu-list-arrow=left]')
    const rightArr = document.querySelector('[data-menu-list-arrow=right]')
    const hiddenClass = 'opacity-0'

    if (listWrap) {
        listWrap.addEventListener('scroll', throttle(callback, 10))
        window.addEventListener('resize', throttle(hideArrows, 100))

        hideArrows()
    }

    function callback() {
        if (listWrap.scrollLeft < 10) {
            leftArr.classList.add(hiddenClass)
        } else {
            leftArr.classList.remove(hiddenClass)
        }
        if (listWrap.scrollLeft < (listWrap.scrollWidth - listWrap.clientWidth - 10)) {
            rightArr.classList.remove(hiddenClass)
        } else {
            rightArr.classList.add(hiddenClass)
        }
    }


    function hideArrows() {
        if (window.innerWidth > list.scrollWidth) {
            rightArr.classList.add(hiddenClass)
            listWrap.classList.remove('overflow-x-scroll')
            leftArr.classList.add(hiddenClass)
        } else {
            listWrap.classList.add('overflow-x-scroll')
            rightArr.classList.remove(hiddenClass)
        }
    }

    function throttle(fn, wait) {
        var time = Date.now();
        return function() {
            if ((time + wait - Date.now()) < 0) {
                fn();
                time = Date.now();
            }
        }
    }
}