
export default function menuSelect() {
    const select = document.querySelector('[data-menu-select]')
    const options = select.querySelectorAll('option')
    let value
    
    if (select) {
        select.addEventListener('change', function() {
            value = this.value

            console.log(value)
            self.location.href = '#' + value

            select.selectedIndex = 0
        })
    }
}